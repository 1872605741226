import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js';

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const FormContainer = styled.div`
  background-color: #fff;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  margin-bottom: 5px;
  margin-right: 10px;
  width: 100px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px; /* Adicionando um border-radius de 8px para arredondar */
  align-items: center;
`;

const Button = styled.button`
  padding: 12px 20px;
  width: 300px;
  font-size: 16px;
  background-color: #000;
  color: #fff;
  border: none;
  margin-top: 20px;
  border-radius: 8px;
  cursor: pointer;
`;

const Title = styled.label`
  font-size: 30px;
  font-weight: bold;
`;

const LoginForm = (props) => { // Adicione props como parâmetro da função
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Criptografar a senha usando MD5
      const encryptedPassword = CryptoJS.MD5(password).toString();

      const response = await fetch('https://twap.samba-api.com', {
        method: 'POST',
        body: JSON.stringify({ email, password: encryptedPassword }) // Enviar a senha criptografada
      });

      const data = await response.json();

      if (data.status_code === 200 && data.body.success) {
        toast.success('User authenticated successfully!');
        setTimeout(() => {
          // Redirecionar ou fazer outras ações após o login bem-sucedido
        }, 3000);
      
        // Salvar os dados de autenticação no localStorage
        localStorage.setItem('user', JSON.stringify({
          email,
          password: encryptedPassword,
          exp_time: data.body.data.exp_time,
          is_admin: data.body.data.is_admin,
        }));
      
        // Notificar o componente pai (App) que o usuário está autenticado
        props.onLogin(email, encryptedPassword);
      }      
    } catch (error) {
      toast.error('🦄 Wow so easy!');
    }
  };

  return (
    <ModalBackdrop>
      <FormContainer>
        <Title>StableBot</Title>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Email</Label>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </FormGroup>
          <center><Button type="submit">Login</Button></center>
        </Form>
      </FormContainer>
    </ModalBackdrop>
  );
}

export default LoginForm;