import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Modal from 'react-modal';

const UsersContainer = styled.div`
  width: calc(100% - 45px); // Ajusta a largura para corresponder à margem esquerda
  padding: 20px;
  margin-left: 45px; // Mantém a alinhamento à esquerda como nas outras telas
  position: relative; // Necessário para o posicionamento absoluto do botão Add User
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const StyledTable = styled.table`
  width: 95%;
  border-collapse: collapse;
  border-radius: 10px; // Adiciona bordas arredondadas ao formulário
  overflow: hidden; // Garante que as bordas da tabela sejam arredondadas
`;

const StyledThead = styled.thead`
  background-color: #444444;
  color: #ffffff;
`;

const StyledTh = styled.th`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #dddddd; // Adiciona borda ao cabeçalho para separação
`;

const StyledTd = styled.td`
  padding: 10px;
  border-top: 1px solid #dddddd;
`;

const StyledTr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const Button = styled.button`
  padding: 8px 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;

  &:hover {
    background-color: #0056b3;
  }
`;

const ButtonReset = styled.button`
  padding: 8px 16px;
  color: #fff;
  background-color: #F23869;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;

  &:hover {
    background-color: #0056b3;
  }
`;

const AddUserButton = styled(Button)`
  position: absolute;
  top: 25px; // Ajusta para alinhar com o topo do UsersContainer
  right: 80px; // Ajusta conforme necessário para alinhar com a margem direita do UsersContainer
  transform: translateY(50%); // Centraliza o botão verticalmente em relação ao topo do UsersContainer
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: 600;
`;

const Input = styled.input`
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 0 10px;
  font-size: 16px;
`;

const Select = styled.select`
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: white;
  padding: 0 10px;
  font-size: 16px;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SubmitButton = styled(Button)`
  margin-top: 20px; // Ensures that there is some space before the submit button
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; // Default background color
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: #2196F3;
  }

  &:checked + ${Slider}:before {
    transform: translateX(26px);
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px #2196F3;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

Modal.setAppElement('#root');
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '500px', // Largura do modal
    border: '1px solid #ccc', // Borda do modal
    borderRadius: '10px', // Borda arredondada do modal
    padding: '20px', // Espaçamento interno do modal
  },
};

const NewUser = () => {
  const [users, setUsers] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserProduct, setNewUserProduct] = useState('');
  const [newUserIsAdmin, setNewUserIsAdmin] = useState(false);

  useEffect(() => {
    fetchUsers(); // Substitua isso pelo seu método de carregamento de usuários
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleAddUser = () => {
    openModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://twap.samba-api.com/create-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: newUserEmail,
          product: newUserProduct,
          is_admin: newUserIsAdmin ? 1 : 0, // Converte booleano para número inteiro
        }),
      });

      if (response.ok) {
        // Requisição bem-sucedida
        toast.success('User created successfully!');
        closeModal(); // Fecha o modal
        window.location.reload();
        // Você pode adicionar lógica aqui para atualizar a lista de usuários
      } else {
        // Requisição mal-sucedida
        const data = await response.json();
        toast.error(data.message || 'Failed to create user');
      }
    } catch (error) {
      // Erro ao fazer a requisição
      console.error('Failed to create user:', error);
      toast.error('Failed to create user');
    }
  };


  const handleAdminSwitchChange = (event) => {
    setNewUserIsAdmin(event.target.checked);
  };
  

  const fetchUsers = async () => {
    try {
      const response = await fetch('https://twap.samba-api.com/users');
      const data = await response.json();
      if (data.status_code === 200 && data.body.success) {
        setUsers(data.body.data); // Defina os usuários com os dados recebidos
      } else {
        console.error('Failed to fetch users:', data);
      }
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };


  const handleResetPassword = async (email) => {
    try {
      const response = await fetch('https://twap.samba-api.com/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success('Password reset and email sent!');
      } else {
        toast.error(data.message || 'Failed to reset password');
      }
    } catch (error) {
      console.error('Failed to reset password:', error);
      toast.error('Failed to reset password');
    }
  };

  return (
    <UsersContainer>
      <Title>User List</Title>
      <StyledTable>
        <StyledThead>
          <tr>
            <StyledTh>Email</StyledTh>
            <StyledTh>Created At</StyledTh>
            <StyledTh>Actions</StyledTh>
          </tr>
        </StyledThead>
        <tbody>
          {users.map((user) => (
            <StyledTr key={user.id}>
              <StyledTd>{user.email}</StyledTd>
              <StyledTd>{user.created_at}</StyledTd>
              <StyledTd>
                <ButtonReset onClick={() => handleResetPassword(user.email)}>Reset Password</ButtonReset>
              </StyledTd>
            </StyledTr>
          ))}
        </tbody>
      </StyledTable>
      <AddUserButton onClick={handleAddUser}>Add User</AddUserButton>
    <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
      <h2>Add New User</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Email:</Label>
          <Input type="email" value={newUserEmail} onChange={(e) => setNewUserEmail(e.target.value)} required />
        </FormGroup>
        <FormGroup>
          <Label>Product:</Label>
          <Select value={newUserProduct} onChange={(e) => setNewUserProduct(e.target.value)} required>
            <option value="">Select a product</option>
            <option value="Fala Terranova - Grupo VIP">Fala Terranova - Grupo VIP</option>
            <option value="Crypto Coins">Crypto Coins</option>
          </Select>
        </FormGroup>
        <FormGroup>
  <SwitchContainer>
    <Label>Is Admin:</Label>
    <SwitchLabel>
      <SwitchInput type="checkbox" checked={newUserIsAdmin} onChange={handleAdminSwitchChange} />
      <Slider></Slider>
    </SwitchLabel>
  </SwitchContainer>
</FormGroup>

        <SubmitButton type="submit">Add User</SubmitButton>
      </Form>
    </Modal>
  </UsersContainer>
);
}

export default NewUser;