import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaStream, FaCloud, FaSignOutAlt, FaBars, FaTimes, FaPlus, FaChartLine } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom'; 

const SidebarContainer = styled.div`
  width: 250px;
  height: 100%; // Isso vai garantir que a sidebar estenda conforme o conteúdo ou mais
  min-height: 100vh; // Isso garante que a sidebar tenha no mínimo a altura da viewport
  background-color: #ebebeb;
  color: #444;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto; // Permite rolagem se o conteúdo for maior que a tela
  transition: left 0.3s ease-in-out;
  z-index: 1000;

  @media (max-width: 768px) {
    margin-left: ${({ open }) => (open ? '0' : '-250px')};
    min-height: 100vh; // Aqui, mantém a regra min-height para dispositivos móveis.
  }
`;


const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    justify-content: space-between; // Ajusta o espaçamento no modo responsivo
  }
`;

const MenuToggle = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex; // Use flex para centralizar o ícone
    justify-content: flex-end; // Alinha o ícone à direita
    width: 100%; // Ocupa a largura total do cabeçalho
    cursor: pointer;
  }
`;

const SidebarMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const MenuItem = styled.li`
  padding: 15px 10px;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  &:hover, &.active {
    background-color: #fff;
  }
`;

const Icon = styled.span`
  margin-right: 15px;
`;

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation(); 
  const [open, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(location.pathname === '/api' ? 'API' : 'New TWAP');
  const [isAdmin, setIsAdmin] = useState(false);

  const handleAdministrativeClick = () => {
    setActiveItem('Administrative');
    navigate('/administrative');
    setOpen(false);
  };


  useEffect(() => {

    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    if (user && user.is_admin) {
      setIsAdmin(true);
    }

    switch (location.pathname) {
      case '/':
        setActiveItem('New TWAP');
        break;
      case '/active-twaps':
        setActiveItem('Active TWAPs');
        break;
      case '/api':
        setActiveItem('API');
        break;
      case '/administrative':
          setActiveItem('Administrative');
          break;
      // Adicione mais casos conforme necessário
      default:
        setActiveItem('');
    }
  }, [location]);

  const toggleMenu = () => {
    setOpen(!open);
  };

  const showNewTwapScreen = () => {
    setActiveItem('New TWAP'); // Atualiza o item ativo
    navigate('/');
    setOpen(false);
  };

  const handleActiveTwapClick = () => {
    setActiveItem('Active TWAPs'); // Atualiza o item ativo
    navigate('/active-twaps'); // Supondo que você tenha uma rota para isto
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    window.location.reload();
  };

  const handleAPIClick = () => {
    setActiveItem('API');
    navigate('/api');
    setOpen(false);
  };


  return (
    <SidebarContainer open={open}>
      <SidebarHeader>
        <div>Stablebot</div>
        <MenuToggle onClick={toggleMenu}>
          {open ? <FaTimes size={20} /> : <FaBars size={20} />} 
        </MenuToggle>
      </SidebarHeader>
      <SidebarMenu>
      <MenuItem onClick={showNewTwapScreen} className={activeItem === 'New TWAP' ? 'active' : ''}>
        <Icon><FaPlus /></Icon>New TWAP
      </MenuItem>
      <MenuItem onClick={handleActiveTwapClick} className={activeItem === 'Active TWAPs' ? 'active' : ''}>
        <Icon><FaStream /></Icon>Active TWAPs
      </MenuItem>
      <MenuItem onClick={handleAPIClick} className={activeItem === 'API' ? 'active' : ''}>
        <Icon><FaCloud /></Icon>API
      </MenuItem>
      {isAdmin && (
          <MenuItem onClick={handleAdministrativeClick} className={activeItem === 'Administrative' ? 'active' : ''}>
              <Icon><FaChartLine /></Icon>Administrative
          </MenuItem>
      )}
      <MenuItem onClick={handleLogout}>
        <Icon><FaSignOutAlt /></Icon>Logout
      </MenuItem>
    </SidebarMenu>
    </SidebarContainer>
  );
}

export default Sidebar;
