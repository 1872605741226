import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const NewTwapContainer = styled.div`
  width: 100%;
  padding: 20px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-left: 45px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: 45px;
  max-width: 50%;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: 500;
`;

const ResponsiveLabel = styled(Label)`
`;


const Input = styled.input`
  padding: 10px;
  margin-bottom: 25px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const TimeInputContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: row; // Muda para row para manter os itens lado a lado em pequenos dispositivos
    align-items: center; // Alinha os itens ao centro
  }
`;

const TimeInputLabel = styled.span`
  min-width: 50px;
  margin-bottom: 20px;
`;

const TimeInput = styled.input`
  padding: 10px;
  flex: 1;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
`;



const TimeFieldContainer = styled.div`
  display: flex;
  align-items: center; // Certifica que os itens estão alinhados ao centro
  margin-right: 20px; // Espaço entre os campos de tempo

  @media (max-width: 768px) {
    width: 100%; // Utiliza toda a largura disponível para o container
    justify-content: space-between; // Separa o input e o rótulo uniformemente
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const Button = styled.button`
  padding: 10px;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

const AddTwapButton = styled(Button)`
  flex: none;
  background-color: #444444;
  border-radius: 3px;
  margin-right: 10px;
  width: 120px;
  font-weight: 600;
  &:hover {
    background-color: #333;
  }
`;

const CancelButton = styled(Button)`
  flex: none;
  background-color: #ebebeb;
  border-radius: 3px;
  color: #4a4a4a;
  width: 120px;
  font-weight: 600;
  &:hover {
    background-color: #ebebeb;
  }
`;

const BuyButton = styled(Button)`
  flex: 1;
  background-color: ${(props) => (props.active ? '#11cb82' : '#f6f6f6')};
  color: ${(props) => (props.active ? '#ffffff' : '#11cb82')};
  border-radius: 3px;
  font-weight: 600;
`;

const SellButton = styled(Button)`
  flex: 1;
  background-color: ${(props) => (props.active ? '#dc3545' : '#f6f6f6')};
  color: ${(props) => (props.active ? '#ffffff' : 'red')};
  border-radius: 3px;
  font-weight: 600;
`;

const BalanceSection = styled.div`
  padding: 20px;
  margin-top: 30px;
  background-color: #f2f2f2;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between; // Alterado de 'center' para 'space-between'
  align-items: center; // Mantém os itens centralizados verticalmente
  max-width: 50%;
  margin-left: 45px;
  min-height: 100px; // Pode manter uma altura mínima para consistência
`;


const BalanceItem = styled.div`
  text-align: left;
`;

const BalanceTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 5px; // Espaço entre título e valor
`;

const BalanceValue = styled.p`
  font-size: 14px;
  margin: 0;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; // Ocupa toda a largura do contêiner pai
`;


const Spiner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;



const NewTwap = () => {
  const [pairName, setPairName] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [size, setSize] = useState('');
  const [side, setSide] = useState('BUY');
  const [assetSuggestions, setAssetSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [loadingAssets, setLoadingAssets] = useState(true);
  const [error, setError] = useState(null);
  const [allAssets, setAllAssets] = useState([]);
  const [balanceAsset, setBalanceAsset] = useState('');
  const [balanceUSDT, setBalanceUSDT] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [showBalances, setShowBalances] = useState(false);
  const [isBalanceLoaded, setIsBalanceLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleHoursChange = (e) => {
    const val = Math.max(0, Math.min(24, Number(e.target.value))); // Garante que o valor esteja entre 0 e 24
    setHours(val.toString()); // Atualiza o estado com o valor validado
  };

  const handleMinutesChange = (e) => {
    const val = Math.max(0, Math.min(59, Number(e.target.value))); // Garante que o valor esteja entre 0 e 59
    setMinutes(val.toString()); // Atualiza o estado com o valor validado
  };

  useEffect(() => {
    const loadAssets = async () => {
      try {
        const assets = await fetchBinanceAssets();
        setAllAssets(assets); // Armazena todos os ativos
        setAssetSuggestions(assets); // Presumindo que você queira armazenar todos os ativos inicialmente
        setLoadingAssets(false);
      } catch (error) {
        setError(error);
        setLoadingAssets(false);
      }
    };
  
    loadAssets();
  }, []);  

  useEffect(() => {
    const storedApiKey = localStorage.getItem('apiKey');
    const storedApiSecret = localStorage.getItem('apiSecret');
    setApiKey(storedApiKey);
    setApiSecret(storedApiSecret);

    const loadAssets = async () => {
    };
  
    loadAssets();
}, []);

useEffect(() => {
  if (pairName) {
    fetchBalances(); // Busca os saldos para o novo pairName
    setShowBalances(true); // Exibe a seção de saldos
  }
}, [pairName]);

const showToast = (message) => {
  toast.error(message); // Substitua por sua biblioteca de toast preferida
};


const fetchBalances = async () => {
  if (!apiKey || !apiSecret) {
    setIsBalanceLoaded(false); // Garante que o modal fique oculto se as credenciais estiverem ausentes
    return;
  }
  setIsBalanceLoaded(false); // Começa a carregar, então escondemos o modal
  try {
    const response = await fetch('https://twap.samba-api.com/balances', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            api_key: apiKey,
            api_secret: apiSecret,
        }),
    });
    const responseData = await response.json();
    const balances = responseData.body.balance;
    const assetName = pairName.replace('USDT', '');
    const assetBalance = balances.find(b => b.asset === assetName);
    const usdtBalance = balances.find(b => b.asset === 'USDT');
    setBalanceAsset(`Balance: ${assetBalance ? assetBalance.free : '0.00000000'} ${assetName}`);
    setBalanceUSDT(`Balance: ${usdtBalance ? usdtBalance.free : '0.00000000'} USDT`);
    setIsBalanceLoaded(true); // Saldo carregado, podemos mostrar o modal agora
  } catch (error) {
      setIsBalanceLoaded(false); // Erro ao carregar, mantenha o modal oculto
  }
};


const handleChangeSize = (e) => {
  const newValue = Math.max(0, Math.min(100000, Number(e.target.value)));
  setSize(newValue.toString());
};



  const fetchBinanceAssets = async () => {
    try {
      const response = await fetch('https://api.binance.com/api/v3/exchangeInfo');
      const data = await response.json();
      // Filtre apenas os símbolos que terminam com 'USDT'
      return data.symbols
        .filter(symbol => symbol.status === 'TRADING' && symbol.symbol.endsWith('USDT'))
        .map(symbol => symbol.symbol);
    } catch (error) {
      return []; // Retorna uma lista vazia em caso de erro
    }
  };  

  const handleBuyClick = (e) => {
    e.preventDefault(); // Impede o envio do formulário
    setSide('BUY');
  };
  
  const handleSellClick = (e) => {
    e.preventDefault(); // Impede o envio do formulário
    setSide('SELL');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!pairName || !hours || !minutes || !size || !apiKey || !apiSecret) {
      showToast('Please fill in all fields');
      return;
    }

    setIsSubmitting(true);
  
    const durationInSeconds = Number(hours) * 3600 + Number(minutes) * 60;
  
    const twapPayload = {
      api_key: apiKey,
      api_secret: apiSecret,
      usdt_amount: Number(size),
      duration: durationInSeconds,
      side: side,
      symbol: pairName,
    };
  
    try {
      const response = await fetch('https://twap.samba-api.com/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(twapPayload),
      });
  
      const responseData = await response.json();
  
      if (response.ok && responseData.body && responseData.body.success) {
        toast.success('TWAP Created Successfully');
        // Aqui você salva o clientAlgoId no localStorage
        saveClientAlgoId(responseData.body.clientAlgoId);
        handleCancel(); // Limpa o formulário após a criação bem-sucedida
      } else {
        // Trate a resposta de erro conforme necessário
        showToast(`Error: ${responseData.body.msg}`);
      }
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      showToast('Error creating TWAP: ' + error.message);
    }
  };  
  

  const handleCancel = () => {
    setPairName('');
    setHours('');
    setMinutes('');
    setSize('');
    setShowBalances(false);
  };

  const saveClientAlgoId = (newAlgoId) => {
  // Obtenha a lista atual de IDs, ou inicie uma nova lista se não houver nenhuma
  const currentIds = JSON.parse(localStorage.getItem('clientAlgoIds')) || [];
  // Adicione o novo ID
  const updatedIds = [...currentIds, newAlgoId];
  // Salve a lista atualizada de volta no localStorage
  localStorage.setItem('clientAlgoIds', JSON.stringify(updatedIds));
};



  const handlePairNameChange = (e) => {
    const input = e.target.value.toUpperCase();
    setPairName(input);
  
    if (input.length > 0) {
        const filteredAssets = allAssets.filter(symbol => symbol.startsWith(input));
        setAssetSuggestions(filteredAssets);
        setShowSuggestions(true);
    } else {
        setAssetSuggestions(allAssets);
        setShowSuggestions(false);
        setShowBalances(false); // Esconde o modal se não há nada digitado no input
        setIsBalanceLoaded(false); // Resetamos também o estado de carregamento dos saldos
    }
  };
  
  

  return (
    <NewTwapContainer>
      <Title>New TWAP</Title>
      <Form onSubmit={handleSubmit}>
        <Label htmlFor="pairName">Pair Name</Label>
        <Input
          type="text"
          id="pairName"
          value={pairName}
          onChange={handlePairNameChange}
          placeholder="ETHUSDT"
        />
        {showSuggestions && (
          <div style={{
            position: 'absolute',
            backgroundColor: 'white',
            border: '1px solid #ccc',
            minWidth: '200px',
            maxHeight: '300px',
            overflowY: 'auto',
            marginTop: '60px', // Ajusta isso conforme necessário para mudar o posicionamento
          }}>
            {assetSuggestions.map((suggestion, index) => (
              <div
                key={index}
                style={{ padding: '10px', cursor: 'pointer' }}
                onClick={() => {
                  setPairName(suggestion); // Agora isso atualiza pairName e o useEffect subsequente cuida do resto
                  setShowSuggestions(false);
                }}
              >
                {suggestion}
              </div>
            ))}
          </div>
        )}
        <ResponsiveLabel htmlFor="duration">Duration</ResponsiveLabel>
        <TimeInputContainer>
        <TimeFieldContainer>
          <TimeInput
            type="number"
            id="hours"
            value={hours}
            onChange={handleHoursChange}
            placeholder="0 - 24"
          />
        <TimeInputLabel htmlFor="hours">Hours</TimeInputLabel>
          </TimeFieldContainer>
          <TimeFieldContainer>
              <TimeInput
                type="number"
                id="minutes"
                value={minutes}
                onChange={handleMinutesChange}
                placeholder="0 - 59"
              />
              <TimeInputLabel htmlFor="minutes">Minutes</TimeInputLabel>
            </TimeFieldContainer>
      </TimeInputContainer>
        <Label htmlFor="size">Size</Label>
        <Input
          type="text"
          id="size"
          value={size}
          onChange={handleChangeSize}
          placeholder="1000 - 100000"
        />
        <Label htmlFor="side">Side</Label>
        <ButtonContainer>
          <BuyButton type="button" active={side === 'BUY'} onClick={handleBuyClick}>BUY</BuyButton>
          <SellButton type="button" active={side === 'SELL'} onClick={handleSellClick}>SELL</SellButton>
        </ButtonContainer>
        <br />
        <ButtonContainer>
          <AddTwapButton type="submit" disabled={isSubmitting}>
            <center>
              {isSubmitting ? (
                  <>
                      <Spiner style={{ width: '16px', height: '16px', marginRight: '5px' }} /> 
                  </>
              ) : (
                  'Add TWAP'
              )}
              </center>
          </AddTwapButton>
          <CancelButton type="button" onClick={handleCancel}>Cancel</CancelButton>
        </ButtonContainer>
      </Form>
      {showBalances && (
        <BalanceSection>
          {isBalanceLoaded ? (
            <>
              <BalanceItem>
                <BalanceTitle>{pairName.replace('USDT', '')} balance:</BalanceTitle>
                <BalanceValue>{balanceAsset}</BalanceValue>
              </BalanceItem>
              <BalanceItem>
                <BalanceTitle>USDT balance:</BalanceTitle>
                <BalanceValue>{balanceUSDT}</BalanceValue>
              </BalanceItem>
            </>
          ) : (
            <SpinnerContainer>
              <Spiner /> {/* Certifique-se de que Spinner está centralizado */}
            </SpinnerContainer>
          )}
        </BalanceSection>
      )}
      
      


    </NewTwapContainer>
  );
};

export default NewTwap;
