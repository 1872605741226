import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import NewTwap from './NewTwap';
import Administrative from './Administrative';
import ActiveTwaps from './ActiveTwaps';
import LoginForm from './LoginForm';
import API from './API';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AppContainer = styled.div`
  display: flex;
  width: 100vw;
  max-width: 100%;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;
  transition: margin-left 0.3s ease-in-out;

  @media (min-width: 769px) {
    margin-left: ${({ isLoggedIn }) => (isLoggedIn ? '250px' : '0')};
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

function LoginManager({ onLogin }) {
  const navigate = useNavigate();
  const routeLocation = useLocation();

  const handleLogin = (email, password) => {
    onLogin(email, password); // Update the login state in the App component
    const from = routeLocation.state?.from?.pathname || '/api';
    navigate(from, { replace: true });
  };

  return <LoginForm onLogin={handleLogin} />;
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const userToken = localStorage.getItem('user');
    if (userToken) {
      const userData = JSON.parse(userToken);
      const expTime = new Date(userData.exp_time).getTime();
      const currentTime = new Date().getTime();

      if (expTime > currentTime) {
        setIsLoggedIn(true);
        setIsAdmin(userData.is_admin);
      } else {
        localStorage.removeItem('user');
      }
    }
  }, []);

  const handleLogin = (email, password) => {
    setIsLoggedIn(true);
    setIsAdmin(true); // Substitute this as per your admin checking logic
  };

  return (
    <Router>
      <AppContainer>
        {isLoggedIn && <Sidebar />}
        <ContentContainer isLoggedIn={isLoggedIn}>
          <Routes>
            <Route path="/" element={isLoggedIn ? <NewTwap /> : <LoginManager onLogin={handleLogin} />} />
            <Route path="/api" element={isLoggedIn ? <API /> : <LoginManager onLogin={handleLogin} />} />
            <Route path="/active-twaps" element={isLoggedIn ? <ActiveTwaps /> : <LoginManager onLogin={handleLogin} />} />
            <Route path="/administrative" element={isLoggedIn && isAdmin ? <Administrative /> : <LoginManager onLogin={handleLogin} />} />
            {/* Add more routes as needed */}
          </Routes>
        </ContentContainer>
      </AppContainer>
      <ToastContainer />
    </Router>
  );
}

export default App;