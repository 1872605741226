import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

// Estilos
const ActiveTwapContainer = styled.div`
  width: 100%;
  padding: 20px;
  margin-left: 45px;
`;

const InactiveTwapContainer = styled(ActiveTwapContainer)``;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const TwapList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: auto; // Permite o scroll horizontal se o conteúdo exceder a largura da tela
`;

const GridItem = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 0.2fr 0.2fr 0.3fr; // Adicione uma nova fração para a coluna "Reason"
  gap: 10px;
  padding: 5px;
  border-radius: 10px;
  overflow: hidden; // Impede que o conteúdo transborde

  @media (max-width: 768px) {
    grid-template-columns: 0.2fr 0.2fr 0.2fr; // Adapte para telas menores conforme necessário
    .GridItem {
      min-width: 600px; // Isso deve ser ajustado baseado no conteúdo real
    }
  }  
`;

const ItemHeader = styled.div`
  font-weight: bold;
  margin-bottom: 10px; // Adiciona espaço abaixo do cabeçalho para separá-lo dos outros elementos
`;

const ItemHeaderTwap = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 40px;
`;

const ProgressBarContainer = styled.div`
  width: 100%; // Garante que a ProgressBarContainer tome todo o espaço disponível
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid black; // Define a cor da borda como preta
  overflow: hidden;
  margin-top: 15px; // Aumenta o espaço acima da barra de progresso
  margin-bottom: 5px; // Aumenta o espaço abaixo da barra de progresso
`;

const ProgressBar = styled.div`
  background-color: #17ca80;
  width: ${props => props.width}; // A largura será controlada pelo valor de progresso
  height: 20px; // Mantém a altura definida anteriormente
  border-radius: 5px;
  position: relative; // Permite que o ProgressLabel seja posicionado em relação a este container
`;

const ProgressBarInactive = styled.div`
  background-color: #a87dff;
  width: ${props => props.width}; // A largura será controlada pelo valor de progresso
  height: 20px; // Mantém a altura definida anteriormente
  border-radius: 5px;
  position: relative; // Permite que o ProgressLabel seja posicionado em relação a este container
`;

const ProgressLabel = styled.span`
  position: absolute; // Posiciona o rótulo de forma absoluta
  top: 0;
  left: 0;
  width: 100%; // Estende o rótulo por toda a largura da barra de progresso
  height: 20px; // Altura igual à da ProgressBar
  display: flex;
  align-items: center; // Centraliza o conteúdo na vertical
  justify-content: center; // Centraliza o conteúdo na horizontal
  color: black;
  font-weight: bold;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const AdditionalInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin-top: 5px;
  margin-bottom: 15px; // Provides space before the next item
`;

const StopButton = styled.button`
  padding: 5px 10px;
  margin-top: 2px;
  margin-left: 20px; // Adiciona um pequeno espaço à esquerda do botão
  background-color: ${props => props.loading ? '#dc3545' : '#dc3545'}; // Mantém a cor vermelha mesmo durante o loading
  color: white; // Texto branco para contraste
  border: none;
  width: 150px;
  border-radius: 5px;
  cursor: ${props => props.loading ? 'default' : 'pointer'}; // Altera o cursor dependendo do estado de carregamento
  transition: opacity 0.3s ease;

  &:hover {
    opacity: ${props => props.loading ? '1' : '0.8'}; // Sutil mudança de opacidade ao passar o mouse, mas apenas se não estiver em estado de carregamento
  }

  &:disabled {
    background-color: #cccccc; // Cor de fundo cinza quando desabilitado
    cursor: default;
    opacity: 0.7;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 90%;
  background-color: #ccc; // Cor cinza para a linha de divisão
  margin-bottom: -10px;
`;

const InactiveTwaps = () => {
    const [twaps, setTwaps] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchTwaps = async () => {
        const apiSecret = localStorage.getItem('apiSecret');
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ api_key: localStorage.getItem('apiKey'), api_secret: apiSecret })
        };
  
        setLoading(true);
        try {
          const response = await fetch('https://twap.samba-api.com/positions_closed', requestOptions);
          const data = await response.json();
          if (data.statusCode === 200) {
            setTwaps(data.body.orders);
          } else {
            throw new Error('Failed to fetch inactive TWAPs');
          }
        } catch (error) {
          toast.error(`Error: ${error.message}`);
        } finally {
          setLoading(false);
        }
      };
  
      fetchTwaps();
    }, []);
  
    return (
      <InactiveTwapContainer>
        <Title>Inactive TWAPs</Title>
        {loading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
            <TwapList>
            {twaps.map((twap, index) => (
              <React.Fragment key={index}>
                <GridItem>
                  <div>
                    <ItemHeader>Type</ItemHeader>
                    <p style={{ color: twap.side === 'BUY' ? '#16cb80' : 'red', fontWeight: 'bold' }}>{twap.side}</p>
                  </div>
                  <div>
                    <ItemHeader>Pair</ItemHeader>
                    <p>{twap.symbol.slice(0, -4) + '/' + twap.symbol.slice(-4)}</p>
                  </div>
                  <div>
                    <ItemHeader>Reason</ItemHeader>
                    <p>{twap.algoStatus}</p>
                  </div>
                  <div>
                    <ItemHeader>Progress</ItemHeader>
                    <ProgressBarContainer>
                      <ProgressBarInactive width={`${(parseFloat(twap.executedQty) / parseFloat(twap.totalQty)) * 100}%`}>
                        <ProgressLabel>{`${Math.round((parseFloat(twap.executedQty) / parseFloat(twap.totalQty)) * 100)}%`}</ProgressLabel>
                      </ProgressBarInactive>
                    </ProgressBarContainer>
                  </div>
                </GridItem>
                <Divider />
                <AdditionalInfoContainer>
                  <div>
                      <ItemHeader>Total Quantity</ItemHeader>
                      <p>{twap.totalQty}</p>
                  </div>
                  <div>
                      <ItemHeader>Executed Quantity</ItemHeader>
                      <p>{twap.executedQty}</p>
                  </div>
                  <div>
                      <ItemHeader>Executed USDT</ItemHeader>
                      <p>{twap.executedAmt}</p>
                  </div>
                  <div>
                      <ItemHeader>Average Price</ItemHeader>
                      <p>{twap.avgPrice}</p>
                  </div>
                  <div>
                      <ItemHeader>Start at</ItemHeader>
                      <p>{new Date(twap.bookTime).toLocaleString()}</p>
                  </div>
                  <div>
                      <ItemHeader>End at</ItemHeader>
                      <p>{twap.endTime ? new Date(twap.endTime).toLocaleString() : 'N/A'}</p>
                  </div>
                </AdditionalInfoContainer>
              </React.Fragment>
            ))}
          </TwapList>
        )}
      </InactiveTwapContainer>
    );
  };

// Componente ActiveTwaps
const ActiveTwaps = () => {
  const [twaps, setTwaps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stopLoading, setStopLoading] = useState(false);

  useEffect(() => {
    const fetchTwaps = async () => {
      const apiSecret = localStorage.getItem('apiSecret');
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ api_key: localStorage.getItem('apiKey'), api_secret: apiSecret })
      };

      setLoading(true);
      try {
        const response = await fetch('https://twap.samba-api.com/positions', requestOptions);
        const data = await response.json();
        if (data.statusCode === 200) {
          setTwaps(data.body.orders);
        } else {
          throw new Error('Failed to fetch active TWAPs');
        }
      } catch (error) {
        toast.error(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchTwaps();
  }, []);

  const handleStopButtonClick = async (algoId) => {
    // Função para exibir toast de confirmação
    const confirmCancel = window.confirm('Do you confirm twap cancellation?');
    if (!confirmCancel) return; // Retorna se o usuário cancelar

    setStopLoading(true); // Define o estado de carregamento do botão "Stop" como verdadeiro ao clicar
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ api_key: localStorage.getItem('apiKey'), api_secret: localStorage.getItem('apiSecret'), algo_id: algoId })
      };
      const response = await fetch('https://twap.samba-api.com/cancel', requestOptions);
      const data = await response.json();
      if (data.statusCode === 200) {
        toast.success('TWAP stopped successfully');
        window.location.reload();
      } else {
        throw new Error('Failed to stop TWAP');
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setStopLoading(false); // Define o estado de carregamento do botão "Stop" como falso após a conclusão da requisição
    }
  };


  return (
    <ActiveTwapContainer>
      <Title>Active TWAPs</Title>
      {loading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <TwapList>
          {twaps.map((twap, index) => (
            <React.Fragment key={index}>
              <GridItem>
                <div>
                  <ItemHeader>Type</ItemHeader>
                  <p style={{ color: twap.side === 'BUY' ? '#16cb80' : 'red', fontWeight: 'bold' }}>{twap.side}</p>
                </div>
                <div>
                  <ItemHeader>Pair</ItemHeader>
                  <p>{twap.symbol.slice(0, -4) + '/' + twap.symbol.slice(-4)}</p>
                </div>
                <div>
                  <ItemHeader>Progress</ItemHeader>
                  <ProgressBarContainer>
                    <ProgressBar width={`${(parseFloat(twap.executedQty) / parseFloat(twap.totalQty)) * 100}%`}>
                      <ProgressLabel>{`${Math.round((parseFloat(twap.executedQty) / parseFloat(twap.totalQty)) * 100)}%`}</ProgressLabel>
                    </ProgressBar>
                  </ProgressBarContainer>
                </div>
                <div>
                <ItemHeaderTwap>TWAP Action</ItemHeaderTwap>
                <StopButton onClick={() => handleStopButtonClick(twap.algoId)} disabled={stopLoading} loading={stopLoading}>
                    {stopLoading ? <Spinner /> : "Stop"}
                </StopButton>
                </div>
              </GridItem>
              <Divider /> {/* Sempre adicionar a linha de divisão após cada GridItem */}
              <AdditionalInfoContainer>
                <div>
                    <ItemHeader>Balance to use</ItemHeader>
                    <p>{parseFloat(twap.executedAmt).toString()}</p>
                </div>
                <div>
                    <ItemHeader>Duration</ItemHeader>
                    <p>{twap.endTime ? `${((twap.endTime - twap.bookTime) / 1000 / 60).toFixed(2)} minutes` : 'Running'}</p>
                </div>
                <div>
                    <ItemHeader>Start at</ItemHeader>
                    <p>{new Date(twap.bookTime).toLocaleString()}</p>
                </div>
                <div>
                    <ItemHeader>End at</ItemHeader>
                    <p>{twap.endTime ? new Date(twap.endTime).toLocaleString() : 'Ongoing'}</p>
                </div>
                <div>
                    <ItemHeader>Average Price</ItemHeader>
                    <p>{twap.avgPrice}</p>
                </div>
                <div>
                    <ItemHeader>Status</ItemHeader>
                    <p>{twap.algoStatus}</p>
                </div>
                </AdditionalInfoContainer>
            </React.Fragment>
          ))}
        </TwapList>
      )}
    </ActiveTwapContainer>
  );
};

const TwapsPage = () => {
    return (
      <>
        <ActiveTwaps />
        <InactiveTwaps />
      </>
    );
  };
  
export default TwapsPage;