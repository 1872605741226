import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

// Ajuste o Container para corresponder ao NewTwapContainer
const Container = styled.div`
  width: 100%; // Mantém a largura total para correspondência
  padding: 20px; // Espaçamento interno igual ao NewTwapContainer
  margin-left: 45px; // Alinha com o título e o balance do NewTwapContainer
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; // Mantém o mesmo espaçamento inferior
`;

const Label = styled.label`
  margin-bottom: 5px; // Espaçamento abaixo do rótulo
  font-weight: 500; // Peso da fonte para o rótulo
`;

const Input = styled.input`
  padding: 10px; // Espaçamento interno para o input
  margin-bottom: 25px; // Espaçamento abaixo do input
  border-radius: 10px; // Bordas arredondadas
  width: 80%;
  border: 1px solid #ccc; // Cor da borda
`;

const Button = styled.button`
  margin-top: 20px; // Espaçamento acima do botão
  padding: 10px; // Espaçamento interno do botão
  background-color: #444444; // Cor de fundo do botão
  color: white; // Cor do texto do botão
  border: none; // Sem borda
  font-weight: 600;
  cursor: pointer; // Cursor de ponteiro
  width: 120px;
  border-radius: 3px;
  &:hover {
    background-color: #333; // Cor de fundo ao passar o mouse
  }
`;

const RequestType = styled.div`
  margin-top: 10px;
`

const RequestType2 = styled.div`
  margin-top: 5px;
`

const API = () => {
    const [apiKey, setApiKey] = useState('');
    const [apiSecret, setApiSecret] = useState('');
    const [requestType, setRequestType] = useState('proxy');
  
    useEffect(() => {
        const storedApiKey = localStorage.getItem('apiKey');
        const storedApiSecret = localStorage.getItem('apiSecret');
        const storedRequestType = localStorage.getItem('requestType'); // Carregar o tipo de requisição
    
        if (storedApiKey) setApiKey(storedApiKey);
        if (storedApiSecret) setApiSecret(storedApiSecret);
        if (storedRequestType) setRequestType(storedRequestType); // Definir o tipo de requisição
    }, []);
    
  
    const handleSave = (e) => {
        e.preventDefault();
      
        if (!apiKey || !apiSecret || !requestType) {
          // Exibir um toast de erro se algum campo estiver vazio
          toast.error('Please fill in all fields.');
          return; // Parar a execução se houver campos vazios
        }
      
        // Salvar as chaves API e o tipo de requisição no localStorage
        localStorage.setItem('apiKey', apiKey);
        localStorage.setItem('apiSecret', apiSecret);
        localStorage.setItem('requestType', requestType);
      
        toast.success('API credentials and request type updated successfully!');
    };      
  
    return (
      <Container>
        <h2>API Credentials</h2>
        <p>
          Enter your binance API credentials below:
        </p>
        <br></br>
        <Form onSubmit={handleSave}>
            <Label htmlFor="apiKey">API KEY</Label>
            <Input 
                type="password"
                id="apiKey"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
            />
            <Label htmlFor="apiSecret">API SECRET</Label>
            <Input 
                type="password"
                id="apiSecret"
                value={apiSecret}
                onChange={(e) => setApiSecret(e.target.value)}
            />
            <div>
                <Label htmlFor="requestType">Request Type:</Label>
                <RequestType>
                <input 
                    type="radio" 
                    id="proxy" 
                    name="requestType" 
                    value="proxy" 
                    checked={requestType === 'proxy'} 
                    onChange={() => setRequestType('proxy')}
                    disabled={true}
                />
                <label htmlFor="proxy">Use request with proxy</label>
                </RequestType>
                <RequestType2>
                <input 
                    type="radio" 
                    id="direct" 
                    name="requestType" 
                    value="direct" 
                    checked={requestType === 'direct'} 
                    onChange={() => setRequestType('direct')}
                    disabled={true}
                />
                <label htmlFor="direct">Use direct request</label>
                </RequestType2>
            </div>
            <Button type="submit">Save</Button>
            </Form>

      </Container>
    );
  }
  
  export default API;
  